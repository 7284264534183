'use strict';
// 图片懒加载
const LazyLoadImg = require('./lazyload.js');
exports.lazyLoad = function() {
  const options = {
    mode: 'diy', // 默认模式，将显示原图，diy模式，将自定义剪切，默认剪切居中部分
    el: document.querySelector('body'),
    diy: { // 设置图片剪切规则，diy模式时才有效果
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
    },
    position: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
    success(el) {
      el.classList.add('success');
    },
  };
  new LazyLoadImg(options);
};

exports._ga = function(action) {
  ga('send', {
    hitType: 'event',
    eventCategory: 'vidmate-2018',
    eventAction: action,
  });
};

/**
 * VidMate客户端提供给H5的专用函数
 * @Author: Golion
 * @Date: 2017.3
 */

const s = 'abcdefghijklmnopqrstuvwxyz';

// 是否可以获取utdid
exports.checkCanGetUid = function() {
  return !(!checkFunc('getClientData') || getClientData(getUidKey()) == '');
};

// 检查VidMate内置函数是否存在（低版本的会不存在，需要返回升级VidMate页面）
exports.checkFunc = function(funcName) {
  if (!window.vbrowser) return false;
  switch (funcName) {
    case 'getClientData':
      return typeof (window.vbrowser.getClientData) === 'function';
    case 'fblogin':
      return typeof (window.vbrowser.vidmateFBLogin) === 'function';
    case 'share':
      return typeof (window.vbrowser.vidmateShare) === 'function';
    case 'copy':
      return typeof (window.vbrowser.vidmateClipboard) === 'function';
    case 'checkApp':
      return typeof (window.vbrowser.vidmateCheckApp) === 'function';
    default:
      return false;
  }
};

// 获取客户端信息
exports.getClientData = function(fieldName) {
  if (!window.vbrowser) {
    return '';
  }
  return window.vbrowser.getClientData(fieldName);
};

// 把utdid混淆一下
exports.getUidKey = function() {
  return s.charAt(20) + s.charAt(19) + s.charAt(3) + s.charAt(8) + s.charAt(3);
};

// H5拉起客户端FB登陆
exports.fblogin = function() {
  if (!window.vbrowser) return false;
  window.vbrowser.vidmateFBLogin();
};

// 注册H5拉起客户端FB登陆的回调
exports.registerFBLoginCallback = function(callback) {
  window.vidmateFBLoginCallback = function(openid, name, image, email) {
    callback(openid, name, image, email);
  };
};

// H5拉起客户端分享
exports.share = function(fbtitle, fbdescr, twitterdescr, othersdescr, image, href, caption, fbid) {
  if (!window.vbrowser) return false;
  window.vbrowser.vidmateShare(
    fbtitle.toString(),
    fbdescr.toString(),
    twitterdescr.toString(),
    othersdescr.toString(),
    image.toString(),
    href.toString(),
    caption.toString(),
    fbid.toString()
  );
};

// H5拉起客户端剪贴板
exports.copy = function(text) {
  if (!window.vbrowser) return false;
  window.vbrowser.vidmateClipboard(text);
};

// 检查包名是否存在（拉起FB分享前需要检查一下）
exports.checkApp = function(appId) {
  if (!window.vbrowser) return false;
  return window.vbrowser.vidmateCheckApp(appId);
};

// 外部浏览器使用端口拉起VidMate，并跳转到xxx
exports.jump = function(type, info) {
  const ifrSrc = 'vidmate://jump/?type=' + encodeURIComponent(type) + '&info=' + encodeURIComponent(info);
  if (!ifrSrc) {
    return;
  }
  const ifr = document.createElement('iframe');
  ifr.src = ifrSrc;
  ifr.style.display = 'none';
  document.body.appendChild(ifr);
  setTimeout(function() {
    document.body.removeChild(ifr);
  }, 1000);
};

/**
 * 从 URL 获取参数
 * @param {*} name 参数名
 * @param {*} url URL地址
 */
exports.getQueryString = function(name, url) {
  try {
    let URLObj = new URL(url);
    return URLObj.searchParams.get(name);
  } catch (error) { // 低版本浏览器兜底方案
    let reg = new RegExp("(^|&|\\?)" + name + "=([^&]*)(&|$)", "i");
    let result = url.match(reg);
    if (result != null) return decodeURI(result[2]);
    return null;
  }
}

let getParams = exports.getParams = function(search, name) {
  /* 去掉头和尾巴 */
  if (search.indexOf('?') < 0) {
      return {}
  }
  search = search.replace(/^.*\?/, '').replace(/\#.*$/, '')
  /* 将躯干切成一段一段 */
  const pairs = search.split('&')

  /* 放入盘中一一摆好 */
  const queryObj = {}
  pairs.forEach(pair => {
      let keyVal = pair.split('=')
      queryObj[keyVal[0]] = decodeURIComponent(keyVal[1])
  })

  /* 就可以开动了 */
  return name ? queryObj[name] : queryObj
};

exports.fillParams = function(params, url, withouts = []) {
  url = url || window.location.href;

  const path = url.split('?')[0] || ''
  const hash = url.split('#')[1] || ''

  /* 拿到url原有的参数，和要注入的参数合并成一个对象 */
  const originParams = getParams(url)
  const paramsToFill = Object.assign({}, originParams, params)

  /* 将每一对参数连接后放入数组 */
  const queries = new Array()
  for (const key in paramsToFill) {
      if (paramsToFill.hasOwnProperty(key)) {
          const element = paramsToFill[key];
          if (withouts.indexOf(key) < 0) {
              queries.push(key + '=' + encodeURIComponent(element))
          }
      }
  }

  /* query和hash */
  const queryStr = queries.length ? '?' + queries.join('&') : ''
  const hashStr = hash ? '#' + hash : ''

  return path + queryStr + hashStr
};