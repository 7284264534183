'use strict';

/* 定义 */
// 懒加载
const utils = require('../common/lib/utils.js');
const pageType = $('#home').attr('data-pagetype');
// 统计点击
const ga = utils._ga;

function gaCountEnter() {
  switch (pageType) {
    case 'home':
      console.log('enter_home');
      ga('enter_home');
      break;
    case 'guide':
      console.log('enter_guide');
      ga('enter_guide');
      break;
    case 'movie':
      console.log('enter_movie_channel');
      ga('enter_movie_channel');
      break;
    case 'music':
      console.log('enter_music_channel');
      ga('enter_music_channel');
      break;
    case 'tvshow':
      console.log('enter_tvshow_channel');
      ga('enter_music_channel');
      break;
    default:
      break;
  }
  $('#home').find('[data-ga]').on('click', function() {
    const gaEvent = $(this).data('ga');
    let gaEventList = [];
    if (!gaEvent) {
      return false;
    }
    gaEventList = gaEvent.split(',');
    for (let i = 0, len = gaEventList.length; i < len; i++) {
      const gaEventItem = gaEventList[i];
      console.log(gaEventItem);
      ga(gaEventItem);
    }
  });
}

function downloadFile(url) {
  try {
    const elemIF = document.createElement('iframe');
    elemIF.src = url;
    elemIF.style.display = 'none';
    document.body.appendChild(elemIF);
  } catch (e) {
    console.log('下载异常！');
  }
}

// home event
function homeEvent() {
  const btnDownload = document.querySelector('.download .btn');
  // const $downloadBtn = $('.download .btn a');
  if (btnDownload) {
    const downloadFix = $('.spread-fixed');
    const downloadScrollT = Math.ceil(btnDownload.getBoundingClientRect().top + btnDownload.getBoundingClientRect().height * 0.3);
    if (downloadScrollT < 0) {
      downloadFix.addClass('show');
    } else {
      downloadFix.removeClass('show');
    }
    $(window).on('scroll', () => {
      const downloadScrollT = Math.ceil(btnDownload.getBoundingClientRect().top + btnDownload.getBoundingClientRect().height * 0.3);
      if (downloadScrollT < 0) {
        downloadFix.addClass('show');
      } else {
        downloadFix.removeClass('show');
      }
    });
  }
}


function init() {
  $('#loading').hide();
  gaCountEnter();
  utils.lazyLoad();
}

init();
